<template>
  <div class="tw-overflow-hidden tw-shadow tw-animate-pulse sm:tw-rounded-lg tw-bg-gray-100 tw-py-40">
      <clip-loader :loading="isLoading" color="#888888"></clip-loader>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    components: {
        ClipLoader
    }
}
</script>

<style>

</style>