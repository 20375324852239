<template>
  <Layout>
    <PageHeader slot="header">
      <template slot="page-title">
        <h1 class="page-title">New event</h1>
      </template>
    </PageHeader>
    <form @submit.prevent="validateForm" class="tw-mb-12">
      <div class="xl:tw-grid xl:tw-grid-cols-12">
        <div class="xl:tw-col-span-5 xl:tw-pr-8">
          <fieldset>
            <ul class="space-y-4" role="radiogroup">
              <LocationPanel
                :selected="step === 1"
                :latitude="latitude"
                :longitude="longitude"
                :customLocation="customLocation"
                :validate="isValidating"
                :errors="errors"
                @updateErrors="updateErrors"
                @updateLocation="updateLocation"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              />

              <IncidentNamePanel
                :selected="step === 2"
                :title="title"
                :errors="errors"
                :validate="isValidating"
                @updateTitle="updateTitle"
                @updateImage="updateImage"
                @updateErrors="updateErrors"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              />
              <ChooseTagsPanel
                :selected="step === 3"
                :tags="tags_ids"
                :errors="errors"
                :validate="isValidating"
                @updateTags="updateTags"
                @updateErrors="updateErrors"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              />
              <ChooseReactionPanel
                :selected="step === 4"
                :reaction="reaction"
                :errors="errors"
                :validate="isValidating"
                @updateReaction="updateReaction"
                @updateErrors="updateErrors"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              />
              <ChooseSeverityPanel
                :selected="step === 5"
                :severity="severity"
                :errors="errors"
                :validate="isValidating"
                @updateSeverity="updateSeverity"
                @updateErrors="updateErrors"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              />
              <CERatingPanel
                :selected="step === 6"
                :control_effectiveness="control_effectiveness"
                :errors="errors"
                :validate="isValidating"
                @updateCE="updateCE"
                @updateErrors="updateErrors"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              />
              <SuggestActionPanel
                :selected="step === 7"
                :solution="solution"
                :errors="errors"
                :validate="isValidating"
                @updateSolution="updateSolution"
                @updateErrors="updateErrors"
                @nextStep="nextStep"
                class="tw-mt-4 lg:tw-mt-0"
              />
            </ul>
          </fieldset>
          <button
            type="submit"
            class="tw-shadow-lg tw-inline-flex tw-w-full lg:tw-w-auto tw-justify-center tw-py-3 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-text-sm tw-font-medium tw-text-white tw-bg-orange-500 hover:tw-bg-orange-600 focus:tw-outline-none tw-col-start-2 lg:tw-col-start-1"
            v-bind:class="[isSubmitting ? 'disabled:opacity-50' : '']"
          >
            <clip-loader
              :loading="isSubmitting"
              color="#f8f8f8"
              size="15px"
            ></clip-loader>
            <span :class="[isSubmitting ? 'tw-ml-1' : '']">Submit</span>
          </button>
        </div>

        <aside v-if="!isMobile()" class="xl:tw-col-span-7">
          <MapInput
            :lat="latitude"
            :lng="longitude"
            @updateLocation="updateGmapLocation"
            class="tw-overflow-hidden tw-shadow sm:tw-rounded-lg focus:tw-outline-none"
          ></MapInput>
        </aside>
      </div>
      
    </form>
  </Layout>
</template>

<script>
import axios from "axios";
import LocationPanel from "@/components/Events/Create/LocationPanel";
import IncidentNamePanel from "@/components/Events/Create/IncidentNamePanel";
import ChooseTagsPanel from "@/components/Events/Create/ChooseTagsPanel";
import ChooseReactionPanel from "@/components/Events/Create/ChooseReactionPanel";
import ChooseSeverityPanel from "@/components/Events/Create/ChooseSeverityPanel";
import CERatingPanel from "@/components/Events/Create/CERatingPanel";
import SuggestActionPanel from "@/components/Events/Create/SuggestActionPanel";

import MapLoading from "@/components/Map/MapLoading";
import MapLoadingFail from "@/components/Map/MapLoadingFail";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

const MapInput = () => ({
  component: import("@/components/Events/Inputs/MapInput.vue"),
  loading: MapLoading,
  error: MapLoadingFail,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 15000, // 15 secs (in case of reallyyyy slow connections)
});

export default {
  name: "create-event",
  components: {
    MapInput: () =>
      import("@/components/Events/Inputs/MapInput.vue").then((m) => m.default),
    ClipLoader,
    IncidentNamePanel,
    LocationPanel,
    ChooseTagsPanel,
    ChooseReactionPanel,
    ChooseSeverityPanel,
    CERatingPanel,
    SuggestActionPanel,
  },
  data() {
    return {
      tags_ids: [],
      tags: [],
      title: "",
      severity: null,
      control_effectiveness: "50",
      solution: "",
      errors: ["location", "title", "tags", "severity", "reaction"],
      media: 0,
      isLoading: false,
      isSubmitting: false,
      isValidating: false,
      currentLoc: null,
      selectedLoc: null,
      reaction: "",
      latitude: null,
      longitude: null,
      customLocation: false,
      step: 1,
    };
  },
  methods: {
    nextStep(value) {
      this.step = value;
    },
    updateErrors(value) {
      //   console.log(value.name);
      if (value.error === true) {
        // Field contains validation error
        if (!this.errors.includes(value.name)) {
          // Add the field from array if it isn't already in it
          this.errors.push(value.name);
        }
      } else {
        // Field passed validation
        if (this.errors.includes(value.name)) {
          // Remove the field from array if it exists
          this.errors.splice(this.errors.indexOf(value.name), 1);
        }
      }
    },
    validateForm() {
      //   this.errors = []; // clear existing errors
      this.isValidating = false; // reset previous checks
      this.isValidating = true; // trigger all child components to recheck validations
      //   console.log(this.errors);
      if (this.errors.length === 0) {
        this.onSubmit();
      }
      //   var errors = this.$watch(
      //     "errors",
      //     function () {
      //         console.log(this.errors)
      //       if (this.errors.length === 0) {
      //         this.onSubmit();
      //       }
      //       if (errors) {

      //           console.log(this.errors)
      //         // if (this.errors.length === 0) {
      //         //   this.onSubmit();
      //         // }
      //         errors();
      //       }

      //     },
      //     { immediate: true }
      //   );
    },
    updateTags(array) {
      var tags_ids = [];
      tags_ids = array.map((item) => item);
      this.tags_ids = tags_ids;
    },
    updateImage(value) {
      this.media = value;
    },
    updateTitle(value) {
      this.title = value;
    },
    updateSeverity(value) {
      this.severity = value;
    },
    updateReaction(value) {
      this.reaction = value;
    },
    updateCE(value) {
      this.control_effectiveness = value;
    },
    updateSolution(value) {
      this.solution = value;
    },
    updateLocation(value) {
      this.latitude = value.lat;
      this.longitude = value.lng;
    },
    updateGmapLocation(value) {
      this.latitude = value.lat();
      this.longitude = value.lng();
      this.customLocation = true;
    },
    async onSubmit() {
      if (this.isSubmitting === false) {
        this.isSubmitting = true;
        axios
          .post(process.env.VUE_APP_API_ROOT + "incidents/", this.$data)
          .then((response) => {
            this.isSubmitting = false;
            this.$emit("completed", response.data);
            this.$router.push({
              name: "Incident",
              params: { eventID: response.data.id },
            });
          })
          .catch((error) => {
            // handle authentication and validation errors here
            this.errors = error.response.data.errors;
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1.8rem;
}
</style>
