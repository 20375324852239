<template>
  <div
    class="tw-flex tw-items-end tw-overflow-hidden tw-shadow sm:tw-rounded-lg tw-bg-gray-50 tw-py-40 tw-justify-center"
  >
    <div
      class="tw-mx-auto tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-18 tw-w-18 tw-rounded-full tw-bg-red-100 sm:tw-mx-0 sm:tw-h-16 sm:tw-w-16"
    >
      <!-- Heroicon name: exclamation -->
      <svg
        class="tw-h-10 tw-w-10 tw-text-red-600"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>